<!-- 常见问题 -->
<template>
  <div class="commonProblem">
    <el-row>
      <el-col :span="24" :lg="24">
        <div class="pageBox lcontainer">
          <div class="header pc">
            <el-image style="width: 100%;" :src="require('../../assets/images/commonProblem/banner.png')" :fit="'fill'">
            </el-image>
            <div class="title" :class="{ weibei: lang }">{{ $t('faq') }}</div>
          </div>

          <div class="header h5">
            <div class="title">{{ $t('faq') }}</div>
          </div>
          <div class="breadcrumb"> <span
              @click="$router.push({ path: '/registerMeeting' })">{{ $t('participation') }}</span> > <span
              class="g-color">{{ $t('faq') }}</span></div>
          <div class="block">
            <p class="qs-title">{{ $t('faq1') }}</p>
            <p class="x-indent ">{{ $t('faw1') }}</p>
          </div>
          <div class="block m-t-16">
            <p class="qs-title">{{ $t('faq2') }}</p>
            <p class="x-indent ">
              {{ $t('faw2') }}
            </p>
          </div>
          <div class="block m-t-16">
            <p class="qs-title">{{ $t('faq3') }}</p>
            <p class="x-indent ">
              {{ $t('faw3') }}</p>
          </div>

          <div class="block m-t-16">
            <p class="qs-title">{{ $t('faq4') }}</p>
            <p class="x-indent ">
              {{ $t('faw4') }}
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === 'zh-CN';
    },
  },
}
</script>
<style lang="stylus" scoped>
@media screen and (min-width: 728px)
    .commonProblem
      width 100%;
      overflow hidden;
      p,div
       line-height: 1.5
      .pageBox
        .h5 
          display: none
        margin-top: 65px
        margin-bottom: 128px 
        .header
          position: relative
          overflow hidden
          .title
            position absolute
            top 50%
            left 50%
            color #fff
            padding: 0 20px
            height 80px
            line-height 80px
            transform: translate(-50%,-50%)
            text-align center;
            background: rgba(141, 198, 63, 0.9);
            font-weight: 700;
            font-size: 42px;
        .breadcrumb
          text-align: right
          padding: 20px 37px
          font-size 14px
          cursor pointer
        .block
          padding: 30px 37px
          text-align: left
          .qs-title
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: #405E3C;
            margin-bottom: 24px
          .x-indent 
            padding-left:18px

@media screen and (max-width: 1400px)
    .commonProblem
      .pageBox
        .block
         padding-left:60px;
@media screen and (max-width: 728px)
    .commonProblem
      width 100%;
      overflow hidden;
      p,div
       line-height: 1.5
       text-align: justify
      .pageBox
        .pc 
          display: none
        margin-bottom: 48px 
        .header
          position: relative
          overflow hidden
          height: 200px
          background:url('../../assets/images/commonProblem/banner.png') 100% 100%;
          .title
            position absolute
            top 50%
            left 50%
            color #fff
            padding: 14px
            height 60px
            line-height 60px
            transform: translate(-50%,-50%)
            text-align center;
            background: rgba(141, 198, 63, 0.9);
            font-weight: 700;
            font-size: 22px;
        .breadcrumb
          text-align: right
          padding: 20px
          font-size 14px
          cursor pointer
        .block
          padding: 10px 20px
          text-align: left
          .qs-title
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: #405E3C;
            margin-bottom: 14px
          .p-indent 
            padding-left 0 !important
          .x-indent
            font-size:14px;
</style>